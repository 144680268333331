<template>
	<div class="tnc w-4/5 my-4 mx-auto text-justify">
		<TermsAndConditions />
	</div>
</template>

<script>
// @ is an alias to /src
import TermsAndConditions from "@/components/TermsAndConditions.vue";

export default {
	name: "TnC",
	components: {
		TermsAndConditions,
	},
};
</script>
