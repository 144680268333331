<template>
	<div class="tnc w-4/5 my-4 mx-auto text-justify">
		<PrivacyPolicy />
	</div>
</template>

<script>
// @ is an alias to /src
import PrivacyPolicy from "@/components/PrivacyPolicy.vue";

export default {
	name: "TnC",
	components: {
		PrivacyPolicy,
	},
};
</script>
